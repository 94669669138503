<template>
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</template>

<script>

export default {
props: {

},
components: {

},
data() {
    return {

    };
},
created() {

},
mounted() {},
updated() {},
beforeUnmount() {},
computed: {},
methods: {},
};
</script>

